@import '../../../../scss/theme-bootstrap';

.basic-tout {
  // auto-fit styles for tout text content
  .content-over-media__wrapper {
    .elc-rich-text {
      // text-wrapper clamp
      line-height: initial;
      font-size: clamp(1rem, calc(4.16667vw + 0.16667rem), 2.25rem);
      @include breakpoint($medium-up) {
        font-size: clamp(0.8rem, 1.5vw, 1.75rem);
      }
      //text classes overrides
      .title--large {
        font-size: 2em;
      }
      .title--med {
        font-size: 1em;
      }
      .title--small {
        font-size: 0.7em;
      }
      .title--smaller {
        font-size: 0.6em;
      }
      .body-text {
        font-size: 0.7em;
        margin: 1rem 0;
      }
    }
  }
  &__media-wrapper {
    position: relative;
    .basic-tout--row-align &,
    .basic-tout--row-align-reverse & {
      width: calc(50% - 12px);
      @include breakpoint($medium-up) {
        width: initial;
      }
    }
  }
  &__content-wrapper {
    .basic-tout--row-align &,
    .basic-tout--row-align-reverse & {
      @include swap_direction(margin, auto 0 auto auto);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: calc(50% - 12px);
      @include breakpoint($medium-up) {
        display: block;
        width: initial;
      }
    }
    .basic-tout--row-align-reverse & {
      @include swap_direction(margin, auto auto auto 0);
    }
  }
  &__media {
    display: block;
  }
  &__content {
    text-align: center;
    &.content-padding-0 {
      padding: 0;
    }
    &.content-padding-10 {
      padding: 10px;
    }
    &.content-padding-20 {
      padding: 20px;
    }
    &.content-padding-30 {
      padding: 30px;
    }
    &.content-top-margin-negative-25 {
      margin-top: -25px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.content-top-margin-negative-15 {
      margin-top: -15px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.content-top-margin-15 {
      margin-top: 15px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.content-top-margin-25 {
      margin-top: 25px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
  }
  &__content-over-media {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    padding: 33px;
    .basic-tout-no-media-layout & {
      position: relative;
    }
    &.media-padding-0 {
      padding: 0;
    }
    &.media-padding-10 {
      padding: 10px;
    }
    &.media-padding-20 {
      padding: 20px;
    }
    &.media-padding-30 {
      padding: 30px;
    }
  }
  .content-over-media {
    &__text {
      text-align: center;
    }
    &__link {
      margin-top: 15px;
      @include breakpoint($medium-up) {
        margin-top: 13px;
      }
    }
  }
  p {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  video {
    width: 100%;
    height: auto;
  }
  .button {
    &.link {
      background: none;
      border: none;
      &:hover {
        background: none;
        border: none;
      }
    }
    .link {
      border: none;
      background: none;
      &:hover {
        background: none;
        border: none;
      }
      &--inverted {
        border: none;
        background: none;
        &:hover {
          background: none;
          border: none;
        }
      }
    }
    &.link--inverted {
      border: none;
      text-transform: none;
      text-decoration: underline;
      background: none;
      &:hover {
        background: none;
        border: none;
        text-transform: none;
        text-decoration: underline;
      }
    }
  }
  &__wrapper {
    .basic-tout--row-align &,
    .basic-tout--row-align-reverse & {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .basic-tout--row-align-reverse & {
      flex-direction: row-reverse;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    &.tout-margin-0 {
      margin: 0;
    }
    &.tout-margin-10 {
      margin: 10px;
    }
    &.tout-margin-20 {
      margin: 20px;
    }
    &.tout-margin-30 {
      margin: 30px;
    }
    &.tout-top-margin-negative-15 {
      margin-top: -15px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.tout-top-margin-negative-20 {
      margin-top: -20px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.tout-top-margin-negative-25 {
      margin-top: -25px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
    &.tout-top-margin-negative-30 {
      margin-top: -30px;
      @include breakpoint($medium-up) {
        margin-top: initial;
      }
    }
  }
  h1 {
    margin-bottom: 8px;
    @include breakpoint($medium-up) {
      margin-bottom: 4px;
    }
  }
  .site-header & {
    .content & {
      display: flex;
      justify-content: center;
    }
    display: none;
    @include breakpoint($medium-up) {
      float: left;
      display: inline-block;
      text-align: left;
      margin-right: 30px;
      flex: 0 1 auto;
      max-width: 20%;
      .basic-tout__media {
        img {
          width: 100%;
          height: auto;
        }
      }
      &__content {
        text-align: left;
        line-height: 18px;
        max-width: 230px;
        .content-block {
          &__line--header {
            font-size: 12px;
            font-family: $header-font-bold;
            text-transform: uppercase;
            padding: 5px 0;
            min-height: 28px;
            color: $color-white;
            p {
              line-height: auto;
            }
          }
          &__line--content {
            font-size: 14px;
            font-family: $base-font-family;
            min-height: 30px;
            color: $color-white;
            p {
              line-height: 18px;
              word-break: break-word;
            }
          }
          &__link {
            .button {
              margin: 0;
              padding: 0;
              background: none;
              text-transform: capitalize;
              text-decoration: underline;
              font-size: 14px;
              line-height: 22px;
              &:hover {
                background: none;
                color: $color-white;
              }
            }
            a {
              font-weight: normal;
            }
          }
        }
        &-wrapper {
          min-height: 65px;
        }
      }
    }
    &__rating-stars {
      min-height: 30px;
      padding-top: 10px;
      &.rating-stars-tout-4 {
        background-image: url('/media/images/global/stars/gg_star_white.svg');
        height: 15px;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        &:last-child {
          background-image: url('/media/images/global/stars/gg_star_gray.svg');
        }
      }
      &.rating-stars-tout-5 {
        background-image: url('/media/images/global/stars/gg_star_white.svg');
        height: 15px;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
      }
      &.rating-stars-tout-default {
        display: none;
      }
    }
  }
}
